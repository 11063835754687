import type {
  ClimateScenario,
  RiskClassEnum,
  SubHazardType,
} from "@/apis/services/HazardService";

type OPTIONS_TYPE =
  | "total"
  | "subHazardType"
  | "risk_class"
  | "climate_scenario"
  | "time_horizon"
  | "assessments";

export type OpenSearchData = { key: number | string; doc_count: number };

export const OPTIONS_TYPE_LOOKUP: { [key: string]: string } = {
  subHazardType: "subHazardType",
  risk_class: "riskClasses",
  climate_scenario: "climateScenario",
  time_horizon: "timeHorizon",
};

export type OpenSearchOptions = {
  type?: OPTIONS_TYPE;
  subHazardType?: SubHazardType | "all";
  riskClasses?: RiskClassEnum[];
  climateScenario?: ClimateScenario | "all";
  timeHorizon?: number | "all";
  sort?: { key: "key" | "doc_count" };
  ignoreKey?: string;
  size?: number;
};

const OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardType: "subhazard_type.keyword",
  consequence: "consequence_type.keyword",
  riskClasses: "risk_class",
  climateScenario: "climate_scenario.keyword",
  rcpScenario: "rcp_scenario.keyword",
  timeHorizon: "time_horizon",
};

export const RISK_RATING_OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardType: `${OPEN_SEARCH_KEYS.subHazardType}`,
  consequence: `${OPEN_SEARCH_KEYS.consequence}`,
  rcpScenario: `${OPEN_SEARCH_KEYS.rcpScenario}`,
  timeHorizon: `${OPEN_SEARCH_KEYS.timeHorizon}`,
};

export const ASSESSMENT_OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardType: `assessment.${OPEN_SEARCH_KEYS.subHazardType}`,
  riskClasses: `assessment.${OPEN_SEARCH_KEYS.riskClasses}`,
  climateScenario: `assessment.${OPEN_SEARCH_KEYS.climateScenario}`,
  timeHorizon: `assessment.${OPEN_SEARCH_KEYS.timeHorizon}`,
};
